import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Alert, Snackbar } from "@mui/material";


export const showMySnackbar = (snacktype, message) => {
  return {
    type: "SETSNACKBAR",
    snackbarType: snacktype,
    snackbarMessage: message,
  };
};
export const clearSnackbar = () => {
  return { type: "SETSNACKBARCLEAR" };
};

export default function MySnackbar() {
  const dispatch = useDispatch();
  const snackbarType = useSelector((state) => state.snackbarType);
  const snackbarMessage = useSelector((state) => state.snackbarMessage);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={!!snackbarType}
      autoHideDuration={1500}
      onClose={handleClose}
    >
      <Alert severity={snackbarType || "success"} onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
